<template>
  <div class="column no-padding is-full">
    <div class="card">
      <header class="card-header has-background-primary">
        <div @click.stop="$router.go(-1)" class="card-header-icon">
          <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
        </div>
        <p class="card-header-title has-text-light">STASE</p>
        <router-link
          v-if="isLoaded && stase.extra_data.can_add_pspd"
          :to="{ name: 'stase-pspd-add', params: {id: stase.id} }"
          class="card-header-icon has-text-light"
        >
          <b-icon class="has-text-light" size="is-small" icon="plus" />
        </router-link>
        <template v-if="isLoaded && stase.extra_data.can_change">
          <router-link
            :to="{ name: 'stase-change', params: {id: stase.id} }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="edit" />
          </router-link>
          <a class="card-header-icon" @click.stop.prevent="confirmDelete">
            <b-icon icon="trash" size="is-small" class="has-text-light"></b-icon>
          </a>
        </template>
      </header>
      <div class="card-content events-card">
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Angkatan</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ stase.angkatan }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Departemen</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ stase.sub_departemen.nama }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Periode</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ stase.mulai }} - {{ stase.hingga }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Koordinator</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="stase.preceptor">{{ stase.preceptor.nama }}</template>
          </div>
        </div>
        <pspd-table v-if="isLoaded" :stase="stase"></pspd-table>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </div>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import Stase from "../models/stase.js";
import { mapGetters } from "vuex";

export default {
  name: "StaseDetail",
  props: ["title"],
  mixins: [onlineCheckMixin, fetchCreatedMixin, deleteMixin],
  data() {
    this.staseMdl = new Stase();
    this.objectMdl = this.staseMdl;
    return this.staseMdl.getObservables();
  },
  components: {
    PspdTable: () => import("@/apps/stase/components/PspdTable.vue"),
  },
  computed: {
    canDelete() {
      return this.isLoaded && this.stase.extra_data.can_change; // &&
      // this.stase.stasepspd_set.length > 1
    },
    ...mapGetters("accounts", ["isPSPD"]),
  },
  methods: {
    fetchData() {
      this.staseMdl.load(this.$route.params.id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";
.del-btn {
  color: #00d1b2 !important;
}
</style>