import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "stase";
const modelPath = `/${modelName}/`;

class Stase extends ModelBase {
  constructor() {
    let model = {
        id: null,
        angkatan: null,
        sub_departemen: {id: null, nama: ""},
        mulai: null,
        hingga: null,
        preceptor: {id: null, nama: ""},
        stasepspd_set: []
    };
    let requiredFields = ["mulai", "hingga"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  getPayload() {
    let data = super.getPayload();
    delete data["sub_departemen"];
    delete data["stasepspd_set"];
    return data;
  }
}

export default Stase;